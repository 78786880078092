import Blank from "@/components/Routes/BlankRoute";

export default [
    {
        path: '/app/pack',
        meta: {
            title: "APP包管理"
        },
        component: Blank,
        children:[
            {
                path: "version",
                name: "AppPackVersion",
                meta: {
                    title: "版本管理",
                },
                component: () => import ("@/views/app/version/List")
            },
            {
                path: "promote",
                name: "AppPackPromote",
                meta: {
                    title: "渠道包管理",
                },
                component: () => import ("@/views/app/pack/List")
            },
            {
                path: "configuration",
                name: "AppPackOEMConfiguration",
                meta: {
                    title: "OEM配置",
                },
                component: () => import ("@/views/app/configuration/List")
            },
        ]
    },
    {
        path: '/app/resource',
        meta: {
            title: "资源位管理"
        },
        component: Blank,
        children:[
            {
                path: "banner",
                name: "AppResourceBanner",
                meta: {
                    title: "Banner管理",
                },
                component: () => import ("@/views/banner/List")
            },
            {
                path: "rank",
                name: "AppResourceRank",
                meta: {
                    title: "排行榜",
                },
                component: () => import ("@/views/rank/List")
            },
            {
                path: "plate",
                name: "AppResourcePlate",
                meta: {
                    title: "板块管理",
                },
                component: () => import ("@/views/plate/List")
            },
            {
                path: "special",
                name: "AppResourceSpecial",
                meta: {
                    title: "专题管理",
                },
                component: () => import ("@/views/special/List")
            },
            {
                path: "search",
                name: "AppResourceSearch",
                meta: {
                    title: "搜索管理",
                },
                component: () => import ("@/views/search/List")
            },
            {
                path: "screen",
                name: "AppResourceScreen",
                meta: {
                    title: "闪屏/插屏管理",
                },
                component: () => import ("@/views/screen/List")
            },
        ]
    },
    {
        path: '/app/msg',
        meta: {
            title: "消息管理"
        },
        component: Blank,
        children:[
            {
                path: "notice",
                name: "AppMsgNotice",
                meta: {
                    title: "系统公告",
                },
                component: () => import ("@/views/notice/List")
            },
            {
                path: "notice-price",
                name: "AppMsgNoticePrice",
                meta: {
                    title: "降价通知",
                },
                component: () => import ("@/views/notice/PriceList")
            },
            {
                path: "discount-report",
                name: "AppMsgDiscountReport",
                meta: {
                    title: "折扣举报",
                },
                component: () => import ("@/views/discountReport/List")
            },
            {
                path: "message",
                name: "AppMsgMessage",
                meta: {
                    title: "消息记录",
                },
                component: () => import ("@/views/message/List")
            },
        ]
    },
]
