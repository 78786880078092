import axios from 'axios';
import qs from 'qs';
import { makeSign } from "./sign";
import router from "../router";
import { ElMessage } from "element-plus";
import store from "@/store/index";

const filter = function (inObject) {
    const sortedJson = {};
    const sortedKeys = Object.keys(inObject).sort();
    for (let i = 0; i < sortedKeys.length; i++) {
        sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
    }
    return sortedJson;
}

const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 5000,
    headers: {
        'Content-Type': "application/x-www-form-urlencoded"
    },
});


service.interceptors.request.use(
    config => {
        if (config.method === 'get') {
            config.params = config.params || {}
            config.params = makeSign(config.params)
        } else if (config.method === 'post') {
            if (config.data instanceof FormData) {
                config.data = makeSign(config.data)
            } else {
                config.data = config.data || {}
                config.data = makeSign(config.data) // 参数签名
                config.data = qs.stringify(filter(config.data)) // 转form表单
            }
        }
        const localStorageData = store.getters['getLocalStorage'];
        config.headers['from-type'] = window.self !== window.top ? 'ym-service' : ''
        config.headers.token = localStorageData.token
        config.headers.adminid = localStorageData.uid
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
)

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code === 888) {
                store.commit('logout', false)
                router.push('/login')
            }
            if (response.headers['content-disposition']) {
                const disposition = response.headers['content-disposition'];
                let fileName = decodeURI(escape(disposition.split('filename=')[1].split(';')[0]))
                const blob = response.headers['content-type'] == 'text/csv' ? new Blob(["\ufeff"+response.data], {type: 'text/csv,charset=UTF-8'}) : new Blob([response.data], { type: response.headers['content-type'] }) ;
                const downloadElement = document.createElement('a');
                const href = window.URL.createObjectURL(blob); // 创建下载的链接
                downloadElement.href = href;
                downloadElement.download = fileName; // 下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); // 点击下载
                document.body.removeChild(downloadElement); // 下载完成移除元素
                window.URL.revokeObjectURL(href); // 释放掉blob对象

                return response.data
            }
            if (response.data.code !== 200) {
                if((response.data.message).indexOf('\r') > -1 || (response.data.message).indexOf('\n') > -1){
                    const newMsg = (response.data.message).replace(/(\n|\r|\r\n|↵)/g, '<br/>');
                    ElMessage({
                        type:'error',
                        dangerouslyUseHTMLString:true,
                        message:newMsg
                    })
                }else{
                    ElMessage.error(response.data.message)
                }
                return Promise.reject(response.data.message)
            }
            return response.data;
        } else {
            ElMessage.error("系统错误")
            return Promise.reject('请求失败');
        }
    },
    error => {
        console.log(error);
        //网络超时异常处理
        if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
            ElMessage.error("请求超时，请稍后重试")
            return Promise.reject('请求超时，请稍后重试');
        } else {
            if((error.message).indexOf('\r') > -1 || (error.message).indexOf('\n') > -1){
                const newMsg = (error.message).replace(/(\n|\r|\r\n|↵)/g, '<br/>');
                console.log(111,newMsg)
                ElMessage({
                    type:'error',
                    dangerouslyUseHTMLString:true,
                    message:newMsg
                })
            }else{
                ElMessage.error(`请求失败:${error.message}`)
            }

            return Promise.reject(`请求失败:${error.message}`);
        }

    }
)

export default service;
