// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/18
// +-------------------------------------------------------------
// |Time: 15:25
// +-------------------------------------------------------------
export default [
    {
        path: 'auth/node',
        name: "AuthNodeList",
        meta: {
            title: "节点列表"
        },
        component: () => import("@/views/auth/Node/List")
    },
    {
        path: 'auth/group',
        name: "AuthGroupList",
        meta: {
            title: "角色列表"
        },
        component: () => import("@/views/auth/Group/List")
    },
    {
        path: 'auth/admin',
        name: "AuthAdminList",
        meta: {
            title: "管理员列表"
        },
        component: () => import("@/views/auth/Admin/List")
    },
]
