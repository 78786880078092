export default [
    {
        path: '/promote/index',
        name: "PromoteIndexList",
        meta: {
            title: "渠道列表"
        },
        component: () => import("@/views/promote/index/List")
    },
    {
        path: '/promote/wallet',
        name: "PromoteWalletList",
        meta: {
            title: "渠道钱包管理"
        },
        component: () => import("@/views/promote/wallet/List")
    },
    {
        path: '/promote/wallet-log',
        name: "PromoteWalletLogList",
        meta: {
            title: "渠道钱包明细"
        },
        component: () => import("@/views/promote/walletLog/List"),
    },
    {
        path: '/promote/withdraw',
        name: "PromoteWithdrawList",
        meta: {
            title: "渠道提现管理"
        },
        component: () => import("@/views/promote/withdraw/List")
    },
    {
        path: '/promote/settle',
        name: "PromoteSettleList",
        meta: {
            title: "渠道结算明细"
        },
        component: () => import("@/views/promote/settle/List")
    },
    {
        path: '/promote/level',
        name: "PromoteLevelList",
        meta: {
            title: "渠道等级"
        },
        component: () => import("@/views/promote/level/List")
    },
    {
        path: '/promote/slot-promote',
        name: "SlotPromoteList",
        meta: {
            title: "投放渠道"
        },
        component: () => import("@/views/promote/slotPromote/List")
    },
    {
        path: '/promote/slot-board',
        name: "SlotBoardList",
        meta: {
            title: "投放数据"
        },
        component: () => import("@/views/promote/board/List")
    }
]
