import request from '@/utils/request'
import realRequest from '@/utils/apiRequest'

const requestApi = (obj) => {
    if (obj.data === true) return obj.url
    return realRequest(obj);
}

export const stsToken = () =>
    request({
        url: '/admin/tool/ali-sts-token',
        method: 'post',
        data: {},
    })
export const txStsToken = () =>
    request({
        url: '/admin/tool/tx-sts-token',
        method: 'post',
        data: {},
    })
export const stsTokenImage = () =>
    requestApi({
        url: '/tool/get-ali-oss-sts',
        method: 'post',
        data: {},
    });