<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="move" mode="out-in">
        <keep-alive :include="tagsList">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>
<script>
import {computed} from "vue";
import {useStore} from "vuex";
// 三级路由缓存模板 需要将模板容器组件加到二级includes缓存数据中，二三级页面跳转不会存在
export default {
  name: "BlankRoute",
  setup(){
    const store = useStore();
    const tagsList = computed(() => {
      return store.state.tagsList.map((item) => item.name)
    });
    return {
      tagsList
    }
  }
}
</script>
