export default [
    {
        path: '/config/agreement',
        name: "ConfigAgreement",
        meta: {
            title: "协议配置"
        },
        component: () => import("@/views/config/Agreement")
    },
    {
        path: '/config/sms',
        name: "ConfigSms",
        meta: {
            title: "短信配置"
        },
        component: () => import("@/views/config/Sms")
    },
    {
        path: '/config/settle',
        name: "ConfigSettle",
        meta: {
            title: "结算配置"
        },
        component: () => import("@/views/config/Settle")
    },
    {
        path: '/config/user-withdraw',
        name: "ConfigUserWithdraw",
        meta: {
            title: "用户提现配置"
        },
        component: () => import("@/views/config/UserWithdraw")
    },
    {
        path: '/config/promote-withdraw',
        name: "ConfigPromoteWithdraw",
        meta: {
            title: "用户提现配置"
        },
        component: () => import("@/views/config/PromoteWithdraw")
    },
    {
        path: '/config/wechat',
        name: "ConfigWechat",
        meta: {
            title: "微信配置"
        },
        component: () => import("@/views/config/Wechat")
    },
    {
        path: '/config/ali',
        name: "ConfigAli",
        meta: {
            title: "支付宝配置"
        },
        component: () => import("@/views/config/Ali")
    },
    {
        path: '/config/about-us',
        name: "ConfigAboutUs",
        meta: {
            title: "关于我们"
        },
        component: () => import("@/views/config/AboutUs")
    },
]
