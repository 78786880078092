export function getTime() {
    let timestamp = Date.parse(new Date());
    return  parseInt(timestamp / 1000);
}

export function lastMonth() {
    const d = new Date(getTime() * 1000)
    const d1 = new Date(d.setMonth(d.getMonth() - 1))
    let t1, t2 = 0;
    t1 = new Date(d1.setDate(1)).setHours(0, 0, 0, 0);
    const d2 = new Date(t1);
    t2 = new Date(d2.setMonth(d2.getMonth() + 1)).setMilliseconds(-1);
    return [t1, t2]
}

export function currentMonth() {
    const d = new Date(getTime() * 1000)
    let t1, t2 = 0;
    t1 = new Date(d.setDate(1)).setHours(0, 0, 0, 0);
    const d2 = new Date(t1);
    t2 = new Date(d2.setMonth(d2.getMonth() + 1)).setMilliseconds(-1);
    return [t1, t2]
}

export function currentDay() {
    const d = new Date(getTime() * 1000)
    let t1, t2 = 0;
    t1 = new Date(d).setHours(0, 0, 0, 0);
    const d2 = new Date(t1);
    t2 = new Date(d2.setDate(d2.getDate() + 1)).setMilliseconds(-1);
    return [t1, t2]
}

export function timestampToTime(timestamp, str = 'Y-M-D h:m:s') {
    if (timestamp.toString().length < 13) {
        timestamp = timestamp * 1000
    }
    const date = new Date(timestamp);
    const Y = date.getFullYear();
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    str = str.replace("Y", Y.toString())
    str = str.replace("M", M.toString())
    str = str.replace("D", D.toString())
    str = str.replace("h", h.toString())
    str = str.replace("m", m.toString())
    str = str.replace("s", s.toString())
    return str;
}

export function utcToTimestamp(utc_datetime) {
    // 转为正常的时间格式 年-月-日 时:分:秒
    const T_pos = utc_datetime.indexOf('T');
    const Z_pos = utc_datetime.indexOf('Z');
    const year_month_day = utc_datetime.substr(0, T_pos);
    const hour_minute_second = utc_datetime.substr(T_pos + 1, Z_pos - T_pos - 1);
    const new_datetime = year_month_day + " " + hour_minute_second; // 2017-03-31 08:02:06

    // 处理成为时间戳
    let timestamp = new Date(Date.parse(new_datetime));
    timestamp = timestamp.getTime();
    timestamp = timestamp / 1000;

    // 增加8个小时，北京时间比utc时间多八个时区
    return timestamp + 28800;
}
