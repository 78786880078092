import request from '@/utils/request'

export const login = (telephone, password) =>
    request({
        url: '/admin/login/login',
        method: 'post',
        data: {telephone, password},
    })

export const info = () =>
    request({
        url: '/admin/login/info',
        method: 'post',
        data: {},
    })

export const changePass = (data) =>
    request({
        url: '/admin/login/change-pass',
        method: 'post',
        data: data,
    })
