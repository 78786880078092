export default [
    {
        path: '/game/type',
        name: "GameTypeList",
        meta: {
            title: "游戏类型"
        },
        component: () => import("@/views/game/type/List")
    },
    {
        path: '/game/platform',
        name: "GamePlatformList",
        meta: {
            title: "平台管理"
        },
        component: () => import("@/views/game/platform/List")
    },
    {
        path: '/game/app',
        name: "GameAppList",
        meta: {
            title: "APP游戏"
        },
        component: () => import("@/views/game/app/List")
    },
    {
        path: '/game/platform-app',
        name: "GamePlatformAppList",
        meta: {
            title: "平台游戏"
        },
        component: () => import("@/views/game/platformApp/List"),
    },
    {
        path: '/game/service',
        name: "GameServiceList",
        meta: {
            title: "开服表"
        },
        component: () => import("@/views/game/service/List")
    },
    {
        path: '/game/vip',
        name: "GameVipList",
        meta: {
            title: "VIP表"
        },
        component: () => import("@/views/game/vip/List")
    },
    {
        path: '/game/activity',
        name: "GameActivityList",
        meta: {
            title: "活动管理"
        },
        component: () => import("@/views/game/activity/List")
    },
    {
        path: '/game/profit',
        name: "GameProfitList",
        meta: {
            title: "游戏利润"
        },
        component: () => import("@/views/game/profit/List")
    },
    {
        path: '/game/download-error',
        name: "DownloadErrorList",
        meta: {
            title: "下载链接工单"
        },
        component: () => import("@/views/game/downloadError/List")
    },
    {
        path: '/game/apply',
        name: "ApplyForList",
        meta: {
            title: "用户游戏申请"
        },
        component: () => import("@/views/game/applyFor/List")
    }
]
