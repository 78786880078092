// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2023/3/28
// +-------------------------------------------------------------
// |Time: 15:24
// +-------------------------------------------------------------
export default [
    {
        path: '/data/export',
        name: "DataExportList",
        meta: {
            title: "导出列表"
        },
        component: () => import("@/views/data/export/List")
    }
]
