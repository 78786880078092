// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/18
// +-------------------------------------------------------------
// |Time: 14:02
// +-------------------------------------------------------------
import request from "@/utils/request";

export const menuList = () =>
    request({
        url: '/admin/auth/menu/menu-list',
        method: 'post',
        data: {},
    })

export const ruleList = () =>
    request({
        url: '/admin/auth/menu/rule-list',
        method: 'post',
        data: {},
    })
