<template>
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>


<script>
import {ElConfigProvider} from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default {
  name: 'App',
  components: {
    ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
      defaultHeight: {
        height: ""
      }
    }
  },
  methods: {
    //定义方法，获取高度减去头尾
    getHeight() {
      this.defaultHeight.height = window.innerHeight - 90 + "px";
    }
  },
  created() {
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
  }
}
</script>

<style>
@import "assets/css/main.css";
@import "assets/css/color-dark.css";
</style>
